import {
  IPermissionsWithLabels,
  EPermissions,
  IPermissionsGroups,
} from '@/types'

export const permissionsWithLabels: IPermissionsWithLabels[] = [
  // Permissions CRUD
  {
    permission: EPermissions.ViewPermissionsList,
    label: 'View permissions list',
  },

  // Users CRUD
  {
    permission: EPermissions.ViewUsersList,
    label: 'View users list',
  },
  {
    permission: EPermissions.ViewUser,
    label: 'View user',
  },
  {
    permission: EPermissions.CreateUser,
    label: 'Create user',
  },
  {
    permission: EPermissions.UpdateUser,
    label: 'Update user',
  },
  {
    permission: EPermissions.UpdateUserPassword,
    label: 'Update user password',
  },
  {
    permission: EPermissions.UpdateUserPermissions,
    label: 'Update user permissions',
  },
  {
    permission: EPermissions.DeleteUser,
    label: 'Delete user',
  },

  // File upload
  {
    permission: EPermissions.ViewFilesList,
    label: 'View files list',
  },
  {
    permission: EPermissions.UploadZptFile,
    label: 'Upload .zpt files',
  },
  {
    permission: EPermissions.GenerateFilePublicUrl,
    label: 'Generate public url for file',
  },
  {
    permission: EPermissions.DeleteFile,
    label: 'Delete files',
  },

  // Products CRUD
  {
    permission: EPermissions.ViewProductsList,
    label: 'View products list',
  },
  {
    permission: EPermissions.ViewProduct,
    label: 'View product',
  },
  {
    permission: EPermissions.CreateProduct,
    label: 'Create product',
  },
  {
    permission: EPermissions.UpdateProduct,
    label: 'Update product',
  },
  {
    permission: EPermissions.ArchiveProduct,
    label: 'Archive product',
  },

  // Order status CRUD
  {
    permission: EPermissions.CreateOrderStatus,
    label: "Create order's status",
  },
  {
    permission: EPermissions.UpdateOrderStatus,
    label: "Update order's status",
  },
  {
    permission: EPermissions.ArchiveOrderStatus,
    label: "Archive order's status",
  },
  {
    permission: EPermissions.UpdateOrderStatusesOrder,
    label: "Update order's status position in list",
  },

  // Client CRUD
  {
    permission: EPermissions.ViewClientsList,
    label: 'View clients list',
  },
  {
    permission: EPermissions.ViewClient,
    label: 'View client',
  },
  {
    permission: EPermissions.CreateClient,
    label: 'Create client',
  },
  {
    permission: EPermissions.UpdateClient,
    label: 'Update client',
  },
  {
    permission: EPermissions.ArchiveClient,
    label: 'Archive client',
  },
  // Poster CRUD
  {
    permission: EPermissions.ViewPostersList,
    label: 'View posters list',
  },
  {
    permission: EPermissions.ViewPoster,
    label: 'View poster',
  },
  {
    permission: EPermissions.CreatePoster,
    label: 'Create poster',
  },
  {
    permission: EPermissions.UpdatePoster,
    label: 'Update poster',
  },
  {
    permission: EPermissions.ArchivePoster,
    label: 'Archive poster',
  },

  // Order CRUD
  {
    permission: EPermissions.ViewOrdersList,
    label: 'View orders list',
  },
  {
    permission: EPermissions.ViewOrder,
    label: 'View order',
  },
  {
    permission: EPermissions.CreateOrder,
    label: 'Create order',
  },
  {
    permission: EPermissions.UpdateStatusOrder,
    label: 'Update order status',
  },
  {
    permission: EPermissions.UpdateOrder,
    label: 'Update order',
  },
  {
    permission: EPermissions.ArchiveOrder,
    label: 'Archive order',
  },

  // File optimization worker
  {
    permission: EPermissions.ViewFilesOptimizationWorker,
    label: 'View files optimization workers',
  },
  {
    permission: EPermissions.ViewFileOptimizationWorker,
    label: 'View files optimization worker',
  },
  {
    permission: EPermissions.UpdateFileOptimizationWorker,
    label: 'Update files optimization worker',
  },
]

export const permissionsGroups: IPermissionsGroups[] = [
  {
    group: 'Permissions',
    permissions: [EPermissions.ViewPermissionsList],
  },
  {
    group: 'Users',
    permissions: [
      EPermissions.ViewUsersList,
      EPermissions.ViewUser,
      EPermissions.CreateUser,
      EPermissions.UpdateUser,
      EPermissions.UpdateUserPassword,
      EPermissions.UpdateUserPermissions,
      EPermissions.DeleteUser,
    ],
  },
  {
    group: 'Files',
    permissions: [
      EPermissions.ViewFilesList,
      EPermissions.UploadZptFile,
      EPermissions.GenerateFilePublicUrl,
      EPermissions.DeleteFile,
    ],
  },
  {
    group: 'Products',
    permissions: [
      EPermissions.ViewProductsList,
      EPermissions.ViewProduct,
      EPermissions.CreateProduct,
      EPermissions.UpdateProduct,
      EPermissions.ArchiveProduct,
    ],
  },
  {
    group: 'Order status',
    permissions: [
      EPermissions.CreateOrderStatus,
      EPermissions.UpdateOrderStatus,
      EPermissions.ArchiveOrderStatus,
      EPermissions.UpdateOrderStatusesOrder,
    ],
  },
  {
    group: 'Clients',
    permissions: [
      EPermissions.ViewClientsList,
      EPermissions.ViewClient,
      EPermissions.CreateClient,
      EPermissions.UpdateClient,
      EPermissions.ArchiveClient,
    ],
  },
  {
    group: 'Posters',
    permissions: [
      EPermissions.ViewPostersList,
      EPermissions.ViewPoster,
      EPermissions.CreatePoster,
      EPermissions.UpdatePoster,
      EPermissions.ArchivePoster,
    ],
  },
  {
    group: 'Orders',
    permissions: [
      EPermissions.ViewOrdersList,
      EPermissions.ViewOrder,
      EPermissions.CreateOrder,
      EPermissions.UpdateStatusOrder,
      EPermissions.UpdateOrder,
      EPermissions.ArchiveOrder,
    ],
  },
  {
    group: 'Files optimization workers',
    permissions: [
      EPermissions.ViewFilesOptimizationWorker,
      EPermissions.ViewFileOptimizationWorker,
      EPermissions.UpdateFileOptimizationWorker,
    ],
  },
]
