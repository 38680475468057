import { ref } from 'vue'

import { IUser, IUsersPaginationParams } from '@/types'
import { usersService } from '@/services'

export const useUsers = () => {
  const users = ref<IUser[]>([])
  const user = ref<IUser | null>(null)

  const getUsersPagination = async (params: IUsersPaginationParams) => {
    return usersService.getPagination(params).then((result) => {
      users.value = result.users

      return result
    })
  }

  const get = async (id: number) => {
    return usersService.get(id).then((result) => {
      if (!result) {
        throw new Error('User not found!')
      }

      user.value = result

      return result
    })
  }

  return {
    users,
    user,

    getUsersPagination,
    get,
  }
}
