export enum EPermissions {
  // Permissions CRUD
  ViewPermissionsList = 'viewPermissionsList',

  // Users CRUD
  ViewUsersList = 'viewUsersList',
  ViewUser = 'viewUser',
  CreateUser = 'createUser',
  UpdateUser = 'updateUser',
  UpdateUserPassword = 'updateUserPassword',
  UpdateUserPermissions = 'updateUserPermissions',
  DeleteUser = 'deleteUser',

  // File upload
  ViewFilesList = 'viewFilesList',
  // UploadFile = 'uploadFile',
  UploadZptFile = 'uploadZptFile',
  GenerateFilePublicUrl = 'generateFilePublicUrl',
  DeleteFile = 'deleteFile',

  // Products CRUD
  ViewProductsList = 'viewProductsList',
  ViewProduct = 'viewProduct',
  CreateProduct = 'createProduct',
  UpdateProduct = 'updateProduct',
  ArchiveProduct = 'archiveProduct',

  // Order status CRUD
  CreateOrderStatus = 'createOrderStatus',
  UpdateOrderStatus = 'updateOrderStatus',
  ArchiveOrderStatus = 'archiveOrderStatus',
  UpdateOrderStatusesOrder = 'updateOrderStatusesOrder',

  // Client CRUD
  ViewClientsList = 'viewClientsList',
  ViewClient = 'viewClient',
  CreateClient = 'createClient',
  UpdateClient = 'updateClient',
  ArchiveClient = 'archiveClient',

  // Poster CRUD
  ViewPostersList = 'viewPostersList',
  ViewPoster = 'viewPoster',
  CreatePoster = 'createPoster',
  UpdatePoster = 'updatePoster',
  ArchivePoster = 'archivePoster',

  // Order CRUD
  ViewOrdersList = 'viewOrdersList',
  ViewOrder = 'viewOrder',
  CreateOrder = 'createOrder',
  UpdateStatusOrder = 'updateStatusOrder',
  UpdateOrder = 'updateOrder',
  ArchiveOrder = 'archiveOrder',

  // File optimization worker
  ViewFilesOptimizationWorker = 'viewFilesOptimizationWorker',
  ViewFileOptimizationWorker = 'viewFileOptimizationWorker',
  UpdateFileOptimizationWorker = 'updateFileOptimizationWorker',
}

export interface IPermissionsWithLabels {
  permission: EPermissions
  label: string
}

export interface IPermissionsWithLabelsGrouped {
  group: string
  permissions: IPermissionsWithLabels[]
}

export interface IPermissionsGroups {
  group: string
  permissions: EPermissions[]
}

export interface IPermission {
  id: number
  permission: EPermissions
}

export interface IPermissionList {
  id: number
  label: string
  permission: EPermissions
}

export interface IPermissionsPagination {
  permissions: IPermission[]
  count: number
}

export interface IPermissionsPaginationParams {
  take: number
  skip: number
  sortKey: string
  sortValue: string
  search?: string
}
