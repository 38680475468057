import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { TIndexedObject } from '@/types'

import {
  requestInterceptor,
  requestErrorInterceptor,
  responseInterceptor,
  responseErrorInterceptor,
} from './interceptors'

class HttpService {
  private axios = {} as AxiosInstance

  constructor() {
    this.createAxiosInstance()
    this.registerInterceptors()
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.get(url, config)
  }

  put<T>(
    url: string,
    payload: TIndexedObject,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.axios.put(url, payload, config)
  }

  post<T>(
    url: string,
    payload?: TIndexedObject,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.axios.post(url, payload, config)
  }

  patch<T>(
    url: string,
    payload: TIndexedObject,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.axios.patch(url, payload, config)
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.delete(url, config)
  }

  private createAxiosInstance() {
    this.axios = axios.create({
      // setup root url for all http requests
      baseURL: process.env.VUE_APP_API_URL + '/api',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
    })
  }

  private registerInterceptors() {
    this.axios.interceptors.response.use(
      responseInterceptor,
      responseErrorInterceptor,
    )

    this.axios.interceptors.request.use(
      requestInterceptor,
      requestErrorInterceptor,
    )
  }
}

export const http = new HttpService()
