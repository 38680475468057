import { RouteRecordRaw } from 'vue-router'

import { EPermissions } from '@/types'
import BlankLayout from '@/layouts/BlankLayout.vue'

const ordersRouteNames = {
  ordersRoot: 'ordersRoot',
  orders: 'orders',
  createOrder: 'createOrder',
  order: 'order',
}

const ordersRoutes: RouteRecordRaw = {
  name: ordersRouteNames.ordersRoot,
  path: '/orders',
  redirect: { name: ordersRouteNames.orders },
  component: BlankLayout,
  children: [
    {
      name: ordersRouteNames.orders,
      path: '',
      meta: {
        title: 'Orders',
        requiresAuth: true,
        permissions: [EPermissions.ViewOrdersList],
        breadcrumbs: [
          {
            label: 'Orders',
          },
        ],
      },
      component: () => import('@/views/orders/Orders.vue'),
    },
    {
      name: ordersRouteNames.createOrder,
      path: 'create',
      meta: {
        title: 'Create order',
        requiresAuth: true,
        permissions: [EPermissions.CreateOrder],
        breadcrumbs: [
          {
            label: 'Orders',
            routeName: ordersRouteNames.orders,
          },
          {
            label: 'Create order',
          },
        ],
      },
      component: () => import('@/views/orders/CreateOrder.vue'),
    },
    {
      name: ordersRouteNames.order,
      path: ':id',
      meta: {
        title: 'Order',
        requiresAuth: true,
        permissions: [EPermissions.ViewOrder],
        breadcrumbs: [
          {
            label: 'Orders',
            routeName: ordersRouteNames.orders,
          },
          {
            label: 'Order',
          },
        ],
      },
      component: () => import('@/views/orders/Order.vue'),
    },
  ],
}

export { ordersRouteNames, ordersRoutes }
