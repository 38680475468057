import { http } from '@/services/http'
import {
  IOrderStatus,
  IOrderStatusesPaginationParams,
  ICreateOrderStatusForm,
  IUpdateOrderStatusForm,
  IUpdateOrderStatusesListForm,
} from '@/types'

class OrderStatusesService {
  getPagination(params: IOrderStatusesPaginationParams) {
    return http.get<IOrderStatus[]>('/order-statuses', { params })
  }

  create(form: ICreateOrderStatusForm) {
    return http.post<IOrderStatus>('/order-statuses', form)
  }

  // get(id: number) {
  //   return http.get<IProduct>(`/order-statuses/${id}`)
  // }

  update(id: number, form: IUpdateOrderStatusForm) {
    return http.patch<IOrderStatus>(`/order-statuses/${id}`, form)
  }

  delete(id: number) {
    return http.delete(`/order-statuses/${id}`)
  }

  updateListOrder(form: IUpdateOrderStatusesListForm) {
    return http.post<IOrderStatus>('/order-statuses/update-order', form)
  }
}

export const orderStatusesService = new OrderStatusesService()
