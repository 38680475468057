import { http } from '@/services/http'
import {
  IProductsPagination,
  IProductsPaginationParams,
  ICreateProductForm,
  IProduct,
  IUpdateProductForm,
  IProductList,
} from '@/types'

class ProductsService {
  getPagination(params: IProductsPaginationParams) {
    return http.get<IProductsPagination>('/products', { params })
  }

  getList() {
    return http.get<IProductList[]>('/products/list')
  }

  create(form: ICreateProductForm) {
    return http.post<IProduct>('/products', form)
  }

  get(id: number) {
    return http.get<IProduct>(`/products/${id}`)
  }

  update(id: number, form: IUpdateProductForm) {
    return http.patch<IProduct>(`/products/${id}`, form)
  }

  delete(id: number) {
    return http.delete(`/products/${id}`)
  }
}

export const productsService = new ProductsService()
