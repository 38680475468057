import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'

import { store } from '@/store/create-store'

export interface ILanguage {
  title: string
  locale: string
  timeFormat: string
  dateFormat: string
  dateTimeFormat: string
}

@Module
class AppStore extends VuexModule {
  lang = localStorage.getItem('lang') || 'en-US'
  isLocalesLoaded = false

  languages: ILanguage[] = [
    {
      // Ukrainian
      title: 'Українська',
      locale: 'uk-UA',
      timeFormat: 'HH:mm',
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm',
    },
    {
      // English (US)
      title: 'English (US)',
      locale: 'en-US',
      timeFormat: 'hh:mm A',
      dateFormat: 'MM/DD/YYYY',
      dateTimeFormat: 'MM/DD/YYYY hh:mm A',
    },
    {
      // English (UK)
      title: 'English (UK)',
      locale: 'en-GB',
      timeFormat: 'HH:mm',
      dateFormat: 'DD/MMMM/YYYY',
      dateTimeFormat: 'DD/MMMM/YYYY HH:mm',
    },
    {
      // German
      title: 'Deutsch',
      locale: 'de-DE',
      timeFormat: 'HH:mm',
      dateFormat: 'YYYY/MM/DD HH:mm',
      dateTimeFormat: 'YYYY/MM/DD HH:mm',
    },
    {
      // French
      title: 'Français',
      locale: 'fr-FR',
      timeFormat: 'HH:mm',
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm',
    },
    {
      // Italian
      title: 'Italiano',
      locale: 'it-IT',
      timeFormat: 'HH.mm',
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH.mm',
    },
    {
      // Spanish
      title: 'Español',
      locale: 'es-ES',
      timeFormat: 'HH:mm',
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm',
    },
    {
      // Czech
      title: 'Česky',
      locale: 'cs-CZ',
      timeFormat: 'HH:mm',
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm',
    },
    {
      // Estonia
      title: 'Eesti',
      locale: 'et-EE',
      timeFormat: 'HH:mm',
      dateFormat: 'DD. MMMM YYYY',
      dateTimeFormat: 'DD. MMMM YYYY HH:mm',
    },
    {
      // Finn
      title: 'Suomalainen',
      locale: 'fi-FI',
      timeFormat: 'HH.mm',
      dateFormat: 'DD. MMMM YYYY',
      dateTimeFormat: 'DD. MMMM YYYY HH.mm',
    },
    {
      // Swedish
      title: 'Svenska',
      locale: 'sv-SE',
      timeFormat: 'HH.mm',
      dateFormat: 'DD.MM.YYYY',
      dateTimeFormat: 'DD.MM.YYYY HH.mm',
    },
    {
      // Polish
      title: 'Polski',
      locale: 'pl-PL',
      timeFormat: 'HH:mm',
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm',
    },
    {
      // Portuguese
      title: 'Português',
      locale: 'pt-PT',
      timeFormat: 'HH:mm',
      dateFormat: 'dd/mm/yyyy',
      dateTimeFormat: 'dd/mm/yyyy HH:mm',
    },
    {
      // Turkish
      title: 'Türk',
      locale: 'tr-TR',
      timeFormat: 'HH:mm',
      dateFormat: 'dd.mm.yyyy',
      dateTimeFormat: 'dd.mm.yyyy HH:mm',
    },
    {
      // Romania
      title: 'Română',
      locale: 'ro-RO',
      timeFormat: 'HH:mm',
      dateFormat: 'dd.mm.yyyy',
      dateTimeFormat: 'dd.mm.yyyy HH:mm',
    },
  ]

  // Getters
  get activeLanguage(): ILanguage {
    return this.languages.find((lang) => lang.locale === this.lang) as ILanguage
  }

  // Mutations
  @Mutation
  setLangMutation(newLang: string) {
    this.lang = newLang
  }

  @Mutation
  setIsLocalesLoaded(value: boolean) {
    this.isLocalesLoaded = value
  }

  // Actions
  @Action
  async setLang(newLang: string) {
    const isLangValid = this.languages.find((lang) => lang.locale === newLang)

    if (isLangValid) {
      this.setLangMutation(newLang)
      localStorage.setItem('lang', newLang)
    }
  }
}

export const appStore = new AppStore({ store, name: 'AppStore' })
