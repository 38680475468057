import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-auto h-full relative" }
const _hoisted_2 = { class: "w-auto h-full overflow-y-auto flex flex-col pb-12 bg-gray-300 border-r border-gray-600" }
const _hoisted_3 = { class: "w-full h-64 flex justify-center items-center" }
const _hoisted_4 = { class: "text-20 font-bold" }
const _hoisted_5 = {
  key: 0,
  class: "bg-gray-300 w-200 text-center px-16 mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Back = _resolveComponent("Back")!
  const _component_ElIcon = _resolveComponent("ElIcon")!
  const _component_ElButton = _resolveComponent("ElButton")!
  const _component_ElMenuItem = _resolveComponent("ElMenuItem")!
  const _component_ElMenu = _resolveComponent("ElMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          (_ctx.isCollapseFinished)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("LV")
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('appName')), 1)
              ], 64))
        ])
      ]),
      _createVNode(_component_ElButton, {
        size: "large",
        circle: "",
        type: "primary",
        class: "absolute right-0 bottom-0 -mr-20 mb-40 z-2001",
        onClick: _ctx.toggleCollapse
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_ElIcon, { size: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_Back, {
                class: _normalizeClass(["transition transform duration-300", { 'rotate-180': _ctx.isCollapse }])
              }, null, 8, ["class"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_ElMenu, {
        "default-active": _ctx.activeMenuItemIndex,
        class: "el-menu-vertical-demo overflow-y-auto border-none",
        collapse: _ctx.isCollapse
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_ElMenuItem, {
              key: index,
              index: index,
              class: _normalizeClass(["text-base bg-gray-300", { 'w-280': !_ctx.isCollapse }]),
              style: {"min-width":"63px"},
              onClick: ($event: any) => (_ctx.onMenuItemClick(item.routeName))
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ElIcon, null, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["index", "class", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["default-active", "collapse"]),
      (_ctx.menuItems.length === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, " You don't have access to any pages "))
        : _createCommentVNode("", true)
    ])
  ]))
}