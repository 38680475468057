import { ElNotification } from 'element-plus'

import { apiResponseErrorMessages } from '@/static/api-response-error-messages'

export const apiErrorNotification = (error: any) => {
  const errorCode = error?.response?.data?.code

  const errorMessage = errorCode
    ? apiResponseErrorMessages[errorCode] || 'Error occurred! Please try again.'
    : 'Error occurred! Please try again.'

  ElNotification({
    title: errorMessage,
    type: 'error',
  })
}

export const asyncTimeout = async (timer = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, timer)
  })
}
