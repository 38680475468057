import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useJwt } from '@vueuse/integrations/useJwt'

import { store } from '@/store/create-store'
import { authService } from '@/services'
import { ILoginForm, IResetPasswordForm, IUser } from '@/types'

const { get, set, remove } = useCookies()

const tokenName = process.env.VUE_APP_TOKEN_KEY

@Module
class AuthStore extends VuexModule {
  token = get(tokenName) || null
  user: IUser | null = null

  // Getters
  get userPermissions() {
    return this.user?.permissions?.map((p) => p.permission) || []
  }

  get userInitials() {
    const firstNameInitial = this.user?.firstName?.[0].toUpperCase() || ''
    const lastNameInitial = this.user?.lastName?.[0].toUpperCase() || ''

    return `${firstNameInitial}${lastNameInitial}`
  }

  get userFullname() {
    return `${this.user?.firstName} ${this.user?.lastName}`
  }

  // Mutations
  @Mutation
  setToken(token: string) {
    this.token = token

    const { payload } = useJwt(token)
    const expiresTimestamp = (payload.value?.exp || 0) * 1000
    set(tokenName, token, {
      path: '/',
      secure: true,
      expires: new Date(expiresTimestamp),
    })
  }

  @Mutation
  removeToken() {
    this.token = null
    remove(tokenName)
  }

  @Mutation
  setUser(user: IUser) {
    this.user = user
  }

  // Actions
  @Action
  async login(loginForm: ILoginForm) {
    const { accessToken } = await authService.login(loginForm)

    this.setToken(accessToken)

    await this.getUser()

    return accessToken
  }

  @Action
  async reset(resetPasswordForm: IResetPasswordForm) {
    const { accessToken } = await authService.resetPassword(resetPasswordForm)

    this.setToken(accessToken)

    await this.getUser()

    return accessToken
  }

  @Action
  async refreshToken() {
    const { accessToken } = await authService.refreshToken()

    this.setToken(accessToken)

    return accessToken
  }

  @Action
  logout() {
    this.removeToken()
    window.location.href = '/auth/login'
  }

  @Action
  async getUser() {
    const user = await authService.getUser()

    this.setUser(user)
  }
}

export const authStore = new AuthStore({ store, name: 'AuthStore' })
