import { Plugin } from 'vue'
import ElementPlus, { ElLoading } from 'element-plus'

import { en } from 'element-plus/lib/locale'

export const ElementPlugin: Plugin = {
  install: (app) => {
    app.use(ElementPlus, {
      locale: en,
    })

    app.use(ElLoading)
  },
}
