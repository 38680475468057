import { http } from '@/services/http'
import { IUploadFileResponse } from '@/types'

class FilesService {
  uploadImage(
    image: FormData,
    groupPrefix?: string,
    config?: any,
  ): Promise<IUploadFileResponse> {
    return http.post<IUploadFileResponse>('/files/upload/image', image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { groupPrefix },
      ...(config || null),
    })
  }

  uploadVideo(
    image: FormData,
    groupPrefix?: string,
    config?: any,
  ): Promise<IUploadFileResponse> {
    return http.post<IUploadFileResponse>('/files/upload/video', image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { groupPrefix },
      ...(config || null),
    })
  }

  uploadZpt(
    image: FormData,
    groupPrefix?: string,
    config?: any,
  ): Promise<IUploadFileResponse> {
    return http.post<IUploadFileResponse>('/files/upload/zpt', image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { groupPrefix },
      ...(config || null),
    })
  }

  generatePublicUrl(id: number): Promise<string> {
    return http.post(`/files/generate-public-url/${id}`)
  }
}

export const filesService = new FilesService()
