import { RouteRecordRaw } from 'vue-router'

import BlankLayout from '@/layouts/BlankLayout.vue'

const arViewRouteNames = {
  arViewRoot: 'arViewRoot',
  arView: 'arView',
}

const arViewRoutes: RouteRecordRaw = {
  name: arViewRouteNames.arViewRoot,
  path: '/ar-view',
  redirect: { name: arViewRouteNames.arView },
  component: BlankLayout,
  children: [
    {
      name: arViewRouteNames.arView,
      path: '',
      meta: {
        title: '',
        requiresAuth: false,
        permissions: [],
        breadcrumbs: [],
      },
      component: () => import('@/views/ar-view/ArView.vue'),
    },
  ],
}

export { arViewRouteNames, arViewRoutes }
