import { RouteRecordRaw } from 'vue-router'

import { EPermissions } from '@/types'
import BlankLayout from '@/layouts/BlankLayout.vue'

const workersRouteNames = {
  workersRoot: 'workersRoot',
  workers: 'workers',
}

const workersRoutes: RouteRecordRaw = {
  name: workersRouteNames.workersRoot,
  path: '/workers',
  redirect: { name: workersRouteNames.workers },
  component: BlankLayout,
  children: [
    {
      name: workersRouteNames.workers,
      path: '',
      meta: {
        title: 'Workers',
        requiresAuth: true,
        permissions: [EPermissions.ViewFilesOptimizationWorker],
        breadcrumbs: [
          {
            label: 'Workers',
          },
        ],
      },
      component: () => import('@/views/workers/Workers.vue'),
    },
  ],
}

export { workersRouteNames, workersRoutes }
