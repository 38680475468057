import { ref } from 'vue'

import { IOrderStatus, IOrderStatusesPaginationParams } from '@/types'
import { orderStatusesService } from '@/services'

export const useOrderStatuses = () => {
  const orderStatuses = ref<IOrderStatus[]>([])

  const getOrderstatusesPagination = async (
    params: IOrderStatusesPaginationParams,
  ) => {
    return orderStatusesService.getPagination(params).then((result) => {
      orderStatuses.value = result

      return result
    })
  }

  return {
    orderStatuses,

    getOrderstatusesPagination,
  }
}
