import { AxiosError, InternalAxiosRequestConfig } from 'axios'

import { authStore } from '@/store'

const requestInterceptor = (
  requestConfig: any,
): InternalAxiosRequestConfig<any> => {
  requestConfig.headers = requestConfig.headers ?? {}

  if (authStore.token) {
    requestConfig.headers['auth'] = authStore.token
  }
  return requestConfig
}

const requestErrorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

export { requestInterceptor, requestErrorInterceptor }
