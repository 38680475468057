import { computed, ref } from 'vue'
import { RouteRecordName } from 'vue-router'

import { authStore } from '@/store'
import {
  EPermissions,
  IPermissionList,
  IPermissionsPaginationParams,
} from '@/types'
import { routeNames } from '@/router'
import { permissionsService } from '@/services'

import {
  permissionsGroups,
  permissionsWithLabels,
} from '@/static/permissions-labels'

interface IMenuItem {
  label: string
  routeName: RouteRecordName
  routeRootName: RouteRecordName
  icon: string
}

export const usePermissions = () => {
  const permissions = ref<IPermissionList[]>([])

  const menuItems = computed(() => {
    const menuArr: IMenuItem[] = []

    if (authStore.userPermissions.includes(EPermissions.ViewUsersList)) {
      menuArr.push({
        label: 'Users',
        routeName: routeNames.users,
        routeRootName: routeNames.usersRoot,
        icon: 'UserFilled',
      })
    }

    if (authStore.userPermissions.includes(EPermissions.ViewPermissionsList)) {
      menuArr.push({
        label: 'Permissions',
        routeName: routeNames.permissions,
        routeRootName: routeNames.permissionsRoot,
        icon: 'Key',
      })
    }

    if (authStore.userPermissions.includes(EPermissions.ViewProductsList)) {
      menuArr.push({
        label: 'Products',
        routeName: routeNames.products,
        routeRootName: routeNames.productsRoot,
        icon: 'Goods',
      })
    }

    // if (authStore.userPermissions.includes(EPermissions.ViewPostersList)) {
    //   menuArr.push({
    //     label: 'Posters',
    //     routeName: '', // TODO
    //     routeRootName: '',
    //     icon: 'Notebook',
    //   })
    // }

    menuArr.push({
      label: "Order's status",
      routeName: routeNames.orderStatuses,
      routeRootName: routeNames.orderStatusesRoot,
      icon: 'CircleCheck',
    })

    if (authStore.userPermissions.includes(EPermissions.ViewOrdersList)) {
      menuArr.push({
        label: 'Orders',
        routeName: routeNames.orders,
        routeRootName: routeNames.ordersRoot,
        icon: 'ShoppingTrolley',
      })
    }

    // if (authStore.userPermissions.includes(EPermissions.ViewFilesList)) {
    //   menuArr.push({
    //     label: 'Files',
    //     routeName: '', // TODO
    //     routeRootName: '',
    //     icon: 'Camera',
    //   })
    // }

    if (authStore.userPermissions.includes(EPermissions.ViewClientsList)) {
      menuArr.push({
        label: 'Clients',
        routeName: routeNames.clients,
        routeRootName: routeNames.clientsRoot,
        icon: 'Avatar',
      })
    }

    if (
      authStore.userPermissions.includes(
        EPermissions.ViewFilesOptimizationWorker,
      )
    ) {
      menuArr.push({
        label: 'Workers',
        routeName: routeNames.workers,
        routeRootName: routeNames.workersRoot,
        icon: 'Cpu',
      })
    }

    return menuArr
  })

  const defaultRedirectRoute = computed(() => {
    return menuItems.value.length
      ? menuItems.value[0].routeName
      : routeNames.accessDenied
  })

  const groupedPermissionsWithLabels = computed(() => {
    return permissionsGroups.map((group) => {
      const permissions = permissionsWithLabels.filter((permissionWithLabel) =>
        group.permissions.includes(permissionWithLabel.permission),
      )

      return {
        group: group.group,
        permissions: permissions,
      }
    })
  })

  const hasPermission = (permission: EPermissions): boolean => {
    return authStore.userPermissions.includes(permission)
  }

  const getPermissionsPagination = async (
    params: IPermissionsPaginationParams,
  ) => {
    return permissionsService.getPagination(params).then((result) => {
      permissions.value = result.permissions.map((permission) => {
        const permissionWithLabel = permissionsWithLabels.find(
          (p) => p.permission === permission.permission,
        )

        return {
          ...permission,
          label: permissionWithLabel?.label || '',
        }
      })

      return result
    })
  }

  return {
    menuItems,
    defaultRedirectRoute,
    hasPermission,
    groupedPermissionsWithLabels,

    getPermissionsPagination,
    permissions,
  }
}
