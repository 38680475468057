import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex items-center justify-between bg-gray-300 h-64" }
const _hoisted_2 = { class: "text-18 max-w-250 truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeFilled = _resolveComponent("HomeFilled")!
  const _component_ElIcon = _resolveComponent("ElIcon")!
  const _component_ElBreadcrumbItem = _resolveComponent("ElBreadcrumbItem")!
  const _component_ElBreadcrumb = _resolveComponent("ElBreadcrumb")!
  const _component_ElAvatar = _resolveComponent("ElAvatar")!
  const _component_ElMenuItem = _resolveComponent("ElMenuItem")!
  const _component_ElSubMenu = _resolveComponent("ElSubMenu")!
  const _component_ElMenu = _resolveComponent("ElMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElMenu, {
      class: "w-full h-full flex justify-between items-center bg-gray-300 pl-18",
      mode: "horizontal",
      "menu-trigger": "click",
      placement: "bottom-end"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElBreadcrumb, {
          "separator-icon": _ctx.ArrowRight,
          class: "text-base"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElBreadcrumbItem, {
              to: { name: _ctx.routeNames.users }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElIcon, { size: "18" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_HomeFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["to"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
              return (_openBlock(), _createBlock(_component_ElBreadcrumbItem, {
                key: index,
                to: { name: item.routeName }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["separator-icon"]),
        _createVNode(_component_ElSubMenu, { placement: "bottom-end" }, {
          title: _withCtx(() => [
            _createVNode(_component_ElAvatar, {
              class: "mr-8",
              size: 32
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.userInitials), 1)
              ]),
              _: 1
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.userFullname), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_ElMenuItem, {
              class: "text-base",
              onClick: _ctx.onProfileClick
            }, {
              default: _withCtx(() => [
                _createTextVNode("Profile")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ElMenuItem, {
              class: "text-base",
              onClick: _ctx.onLogoutClick
            }, {
              default: _withCtx(() => [
                _createTextVNode("Logout")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}