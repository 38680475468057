import { RouteRecordRaw } from 'vue-router'

import { EPermissions } from '@/types'
import BlankLayout from '@/layouts/BlankLayout.vue'

const permissionsRouteNames = {
  permissionsRoot: 'permissionsRoot',
  permissions: 'permissions',
}

const permissionsRoutes: RouteRecordRaw = {
  name: permissionsRouteNames.permissionsRoot,
  path: '/permissions',
  redirect: { name: permissionsRouteNames.permissions },
  component: BlankLayout,
  children: [
    {
      name: permissionsRouteNames.permissions,
      path: '',
      meta: {
        title: 'Permissions',
        requiresAuth: true,
        permissions: [EPermissions.ViewPermissionsList],
        breadcrumbs: [
          {
            label: 'Permissions',
          },
        ],
      },
      component: () => import('@/views/permissions/Permissions.vue'),
    },
  ],
}

export { permissionsRouteNames, permissionsRoutes }
