import { http } from '@/services/http'
import {
  ILoginForm,
  IRequestResetPasswordForm,
  IResetPasswordForm,
  ITokenResponse,
  IUser,
} from '@/types'

class AuthService {
  login(loginForm: ILoginForm) {
    return http.post<ITokenResponse>('/auth/login', loginForm)
  }

  requestResetPassword(requestResetPasswordFrom: IRequestResetPasswordForm) {
    return http.post('/auth/request-reset-password', requestResetPasswordFrom)
  }

  resetPassword(resetPasswordForm: IResetPasswordForm) {
    return http.post<ITokenResponse>('/auth/reset-password', resetPasswordForm)
  }

  getUser() {
    return http.get<IUser>('/auth/me')
  }

  refreshToken() {
    return http.post<ITokenResponse>('/auth/refresh-token')
  }

  // updateUser(updateForm: IUser) {
  //   return http.patch<IUser>('/user', updateForm)
  // }

  // updatePassword(updateForm: IUserPasswords) {
  //   return http.patch<IUserPasswords>('/user/password', updateForm)
  // }
}

export const authService = new AuthService()
