import { http } from '@/services/http'
import { IWorkersPagination, IUpdateWorkerForm, IWorker } from '@/types'

class WorkersService {
  getPagination() {
    return http.get<IWorkersPagination>('/file-optimisation-workers', {
      params: { take: 30, sortKey: 'id', sortValue: 'ASC' },
    })
  }

  // get(id: number) {
  //   return http.get<IWorker>(`/file-optimisation-workers/${id}`)
  // }

  update(id: number, form: IUpdateWorkerForm) {
    return http.patch<IWorker>(`/file-optimisation-workers/${id}`, form)
  }
}

export const workersService = new WorkersService()
