import { ref } from 'vue'

import { IWorker, EWorkersTypes } from '@/types'
import { workersService } from '@/services'

export const useWorkers = () => {
  const workersLabels = {
    [EWorkersTypes.VideoOptimisation]: 'Videos optimisation worker',
    [EWorkersTypes.CleanUnused]: 'Unused files remove worker',
  }

  const workers = ref<IWorker[]>([])

  const getWorkersPagination = async () => {
    return workersService.getPagination().then((result) => {
      workers.value = result.fileOptimisationWorkers

      return result
    })
  }

  return {
    workersLabels,
    workers,

    getWorkersPagination,
  }
}
