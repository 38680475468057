import { RouteRecordRaw } from 'vue-router'

import { EPermissions } from '@/types'
import BlankLayout from '@/layouts/BlankLayout.vue'

const productsRouteNames = {
  productsRoot: 'productsRoot',
  products: 'products',
  createProduct: 'createProduct',
  product: 'product',
}

const productsRoutes: RouteRecordRaw = {
  name: productsRouteNames.productsRoot,
  path: '/products',
  redirect: { name: productsRouteNames.products },
  component: BlankLayout,
  children: [
    {
      name: productsRouteNames.products,
      path: '',
      meta: {
        title: 'Products',
        requiresAuth: true,
        permissions: [EPermissions.ViewProductsList],
        breadcrumbs: [
          {
            label: 'Products',
          },
        ],
      },
      component: () => import('@/views/products/Products.vue'),
    },
    {
      name: productsRouteNames.createProduct,
      path: 'create',
      meta: {
        title: 'Create product',
        requiresAuth: true,
        permissions: [EPermissions.CreateProduct],
        breadcrumbs: [
          {
            label: 'Products',
            routeName: productsRouteNames.products,
          },
          {
            label: 'Create product',
          },
        ],
      },
      component: () => import('@/views/products/CreateProduct.vue'),
    },
    {
      name: productsRouteNames.product,
      path: ':id',
      meta: {
        title: 'Product',
        requiresAuth: true,
        permissions: [EPermissions.ViewProduct],
        breadcrumbs: [
          {
            label: 'Products',
            routeName: productsRouteNames.products,
          },
          {
            label: 'Product',
          },
        ],
      },
      component: () => import('@/views/products/Product.vue'),
    },
  ],
}

export { productsRouteNames, productsRoutes }
