import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

import { appStore } from '@/store'

export const i18n = createI18n({
  locale: appStore.activeLanguage.locale, // set locale
  fallbackLocale: 'en-US', // set fallback locale
})

export const i18nPlugin = {
  install: (app: any) => {
    app.use(i18n)
  },
}

export const loadLocales = async () => {
  // load locale messages with dynamic import
  const locales = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${appStore.lang}.json`
  )

  // set locale and locale message
  i18n.global.setLocaleMessage(appStore.lang, locales.default)
  i18n.global.locale = appStore.lang

  return nextTick()
}
