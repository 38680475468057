import { ref } from 'vue'

interface ICachedData {
  take: number
  skip: number
  page: number
}

export const usePagination = (paginationKey: string) => {
  const paginationKeyName = `${paginationKey}_paginationKey`
  const cachedData = localStorage.getItem(
    paginationKeyName,
  ) as unknown as ICachedData

  const total = ref(0)
  const page = cachedData?.page ? ref(cachedData.page) : ref(1)
  const take = cachedData?.take ? ref(cachedData.take) : ref(10)
  const skip = cachedData?.skip ? ref(cachedData.skip) : ref(0)

  const onUpdatePage = (newPage: number) => {
    page.value = newPage

    skip.value = (page.value - 1) * take.value

    updateStoragePaginationData()
  }

  const onUpdateTake = (newTake: number) => {
    take.value = newTake

    skip.value = (page.value - 1) * take.value

    updateStoragePaginationData()
  }

  const onUpdateTotal = (newTotal: number) => {
    total.value = newTotal
  }

  const updateStoragePaginationData = () => {
    localStorage.setItem(paginationKeyName, {
      skip: skip.value,
      take: take.value,
      page: page.value,
    } as unknown as string)
  }

  return {
    total,
    take,
    skip,
    page,

    onUpdatePage,
    onUpdateTotal,
    onUpdateTake,
  }
}
