import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { ElLoading } from 'element-plus'

import { EPermissions } from '@/types'
import { authStore } from '@/store'
// appStore
import { routeNames } from '@/router'
import { asyncTimeout } from '@/core/helpers'
import { usePermissions } from '@/core/compositions'
// import { loadLocales } from '@/plugins'

const getUser = async () => {
  const loading = ElLoading.service({ fullscreen: true })

  try {
    await asyncTimeout()
    await authStore.getUser()

    await authStore.refreshToken()
  } catch (e) {
    authStore.logout()
  } finally {
    loading.close()
  }
}

// const setI18n = async (to: RouteLocationNormalized) => {
//   if (to.query?.lang) {
//     appStore.setLang(to.query.lang as string)
//   } else if (to.query?.['lang-auto'] === 'true') {
//     const langAuto = window.navigator.language
//     appStore.setLang(langAuto)
//   } else {
//     appStore.setLang('en-US')
//   }

//   if (!appStore.isLocalesLoaded) {
//     await loadLocales()

//     appStore.setIsLocalesLoaded(true)
//   }
// }

export const routeGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { defaultRedirectRoute } = usePermissions()

  const isRouteRequiredAuth = to.meta.requiresAuth

  if (isRouteRequiredAuth && !authStore.token) {
    return next({ name: routeNames.arView })
  }

  if (to.name === routeNames.login && authStore.token) {
    authStore.removeToken()
  }

  // Check if lang exist in query
  // await setI18n(to)

  if (authStore.token && !authStore.user) {
    await getUser()
  }

  // Redirect to Set password page, if account is new
  if (
    authStore.token &&
    authStore.user &&
    authStore.user.isPassword === false &&
    to.name !== routeNames.setPassword
  ) {
    return next({ name: routeNames.setPassword })
  }

  if (
    authStore.token &&
    authStore.user &&
    authStore.user.isPassword &&
    to.name === routeNames.setPassword
  ) {
    return next({ name: defaultRedirectRoute.value })
  }
  // Check user's permissions
  const routerPermissions = (to.meta?.permissions || []) as EPermissions[]
  if (routerPermissions.length) {
    const userPermissions = authStore.userPermissions
    const accessAllowed = routerPermissions.every((permission) =>
      userPermissions.includes(permission),
    )

    if (!accessAllowed) {
      return next({ name: routeNames.accessDenied })
    }
  }

  // Set title in browser tab
  document.title = to.meta?.title
    ? to.meta?.title + ' - ' + process.env.VUE_APP_NAME
    : process.env.VUE_APP_NAME

  next()
}
