export interface IWorker {
  id: number
  type: EWorkersTypes
  isActive: boolean
  createDateTime: Date
  updateDateTime: Date
}

export enum EWorkersTypes {
  VideoOptimisation = 'videoOptimisation',
  CleanUnused = 'cleanUnused',
}

export interface IWorkersPagination {
  fileOptimisationWorkers: IWorker[]
  count: number
}

export interface IWorkersPaginationParams {
  take: number
  skip: number
  sortKey: string
  sortValue: string
  search?: string
}

export interface IUpdateWorkerForm {
  isActive: boolean
}
