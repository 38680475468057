import { ref } from 'vue'

import { IOrder, IOrdersPaginationParams } from '@/types'
import { ordersService } from '@/services'

export const useOrders = () => {
  const orders = ref<IOrder[]>([])

  const getOrdersPagination = async (params: IOrdersPaginationParams) => {
    return ordersService.getPagination(params).then((result) => {
      orders.value = result.orders

      return result
    })
  }

  const get = async (id: number) => {
    return ordersService.get(id).then((result) => {
      if (!result) {
        throw new Error('Order not found!')
      }

      return result
    })
  }

  const regeneratePosterArAnchore = async (posterId: number) => {
    return ordersService.regeneratePosterArAnchore(posterId)
  }

  return {
    orders,

    getOrdersPagination,
    get,
    regeneratePosterArAnchore,
  }
}
