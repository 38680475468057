import { ref } from 'vue'

import { IClient, IClientsPaginationParams } from '@/types'
import { clientsService } from '@/services'

export const useClients = () => {
  const clients = ref<IClient[]>([])

  const getClientsPagination = async (params: IClientsPaginationParams) => {
    return clientsService.getPagination(params).then((result) => {
      clients.value = result.clients

      return result
    })
  }

  const get = async (id: number) => {
    return clientsService.get(id).then((result) => {
      if (!result) {
        throw new Error('Client not found!')
      }

      return result
    })
  }

  return {
    clients,

    getClientsPagination,
    get,
  }
}
