import { RouteRecordRaw } from 'vue-router'
import AuthLayout from '@/layouts/AuthLayout.vue'

const authRouteNames = {
  authRoot: 'authRoot',
  login: 'login',
  forgot: 'forgot',
  reset: 'reset',
  setPassword: 'setPassword',
}

const authRoutes: RouteRecordRaw = {
  name: authRouteNames.authRoot,
  path: '/auth',
  redirect: { name: authRouteNames.login },
  component: AuthLayout,
  children: [
    {
      name: authRouteNames.login,
      path: 'login',
      meta: {
        title: 'Login',
      },
      component: () => import('@/views/auth/Login.vue'),
    },
    {
      name: authRouteNames.forgot,
      path: 'forgot',
      meta: {
        title: 'Forgot password',
      },
      component: () => import('@/views/auth/Forgot.vue'),
    },
    {
      name: authRouteNames.reset,
      path: 'reset',
      meta: {
        title: 'Reset password',
      },
      component: () => import('@/views/auth/Reset.vue'),
    },
    {
      name: authRouteNames.setPassword,
      path: 'set-password',
      meta: {
        title: 'Set password',
        requiresAuth: true,
      },
      component: () => import('@/views/auth/SetPassword.vue'),
    },
  ],
}

export { authRouteNames, authRoutes }
