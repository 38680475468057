
import { defineComponent, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElAvatar,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElIcon,
} from 'element-plus'
import { ArrowRight, HomeFilled } from '@element-plus/icons-vue'

import { authStore } from '@/store'
import { routeNames } from '@/router'

export default defineComponent({
  name: 'AppHeader',

  components: {
    ElMenu,
    ElMenuItem,
    ElSubMenu,
    ElAvatar,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElIcon,
    HomeFilled,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const userInitials = computed(() => {
      return authStore.userInitials
    })

    const userFullname = computed(() => {
      return authStore.userFullname
    })

    const breadcrumbs = computed(() => {
      return route.meta?.breadcrumbs || []
    })

    const onProfileClick = () => {
      router.push({ name: routeNames.profile })
    }

    const onLogoutClick = () => {
      authStore.logout()
    }

    return {
      routeNames,
      userInitials,
      userFullname,
      breadcrumbs,
      onProfileClick,
      onLogoutClick,

      ArrowRight,
    }
  },
})
