import { createApp } from 'vue'

import App from './App.vue'

import router from './router'
import { store } from './store/create-store'
import { ElementPlugin, i18nPlugin } from './plugins'
import { clearConsole } from './core/utils'

if (process.env.NODE_ENV === 'production') {
  clearConsole()
}

import '@/core/utils/storage.utils'
import '@/assets/styles/main.scss'
import '@/assets/fonts/poppins/poppins.css'

export const app = createApp(App)

app.use(store).use(router).use(i18nPlugin).use(ElementPlugin)

router.isReady().then(() => app.mount('#app'))
