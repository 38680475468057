import { RouteRecordRaw } from 'vue-router'

import { EPermissions } from '@/types'
import BlankLayout from '@/layouts/BlankLayout.vue'

const usersRouteNames = {
  usersRoot: 'usersRoot',
  users: 'users',
  createUser: 'createUser',
  user: 'user',
}

const usersRoutes: RouteRecordRaw = {
  name: usersRouteNames.usersRoot,
  path: '/users',
  redirect: { name: usersRouteNames.users },
  component: BlankLayout,
  children: [
    {
      name: usersRouteNames.users,
      path: '',
      meta: {
        title: 'Users',
        requiresAuth: true,
        permissions: [EPermissions.ViewUsersList],
        breadcrumbs: [
          {
            label: 'Users',
          },
        ],
      },
      component: () => import('@/views/users/Users.vue'),
    },
    {
      name: usersRouteNames.createUser,
      path: 'create',
      meta: {
        title: 'Create user',
        requiresAuth: true,
        permissions: [EPermissions.CreateUser],
        breadcrumbs: [
          {
            label: 'Users',
            routeName: usersRouteNames.users,
          },
          {
            label: 'Create user',
          },
        ],
      },
      component: () => import('@/views/users/CreateUser.vue'),
    },
    {
      name: usersRouteNames.user,
      path: ':id',
      meta: {
        title: 'User',
        requiresAuth: true,
        permissions: [EPermissions.ViewUser],
        breadcrumbs: [
          {
            label: 'Users',
            routeName: usersRouteNames.users,
          },
          {
            label: 'User',
          },
        ],
      },
      component: () => import('@/views/users/User.vue'),
    },
  ],
}

export { usersRouteNames, usersRoutes }
