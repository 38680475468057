import { ref } from 'vue'

export const useSort = () => {
  const sortKey = ref<string | null>(null)
  const sortValue = ref<string | null>(null)

  const updateSort = (key: string, value: string) => {
    if (!key || !value) {
      sortKey.value = null
      sortValue.value = null

      return
    }

    if (value && (value === 'descending' || value === 'ascending')) {
      value = value === 'descending' ? 'DESC' : 'ASC'
    }

    sortKey.value = key
    sortValue.value = value
  }

  return {
    sortKey,
    sortValue,

    updateSort,
  }
}
