import { appStore } from '@/store'

export const localeDate = (
  date: string | Date | number,
  type: 'DateTime' | 'Date' | 'Time' = 'DateTime',
  locale?: string,
) => {
  let options = {}

  if (type === 'DateTime') {
    options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
  }

  if (type === 'Date') {
    options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }
  }

  if (type === 'Time') {
    options = {
      hour: 'numeric',
      minute: 'numeric',
    }
  }

  if (!locale) {
    locale = appStore.activeLanguage.locale
  }

  const localizedDate = new Date(date).toLocaleString(locale, options)

  return localizedDate
}

export const localeNumber = (
  number: number,
  decimals = false,
  currency = false,
  locale?: string,
) => {
  const options: any = {
    ...(decimals ? { minimumFractionDigits: 2 } : null),
    ...(currency ? { style: 'currency', currency: 'USD' } : null),
  }

  if (!locale) {
    locale = appStore.activeLanguage.locale
  }

  const localizedNumber = number.toLocaleString(locale, options)

  return localizedNumber
}
