import { http } from '@/services/http'
import {
  IUsersPagination,
  IUsersPaginationParams,
  ICreateUserForm,
  IUser,
  IUserUpdateForm,
  IUserUpdatePasswordForm,
  IUserUpdatePermissionsForm,
  ISetPasswordForm,
} from '@/types'

class UsersService {
  getPagination(params: IUsersPaginationParams) {
    return http.get<IUsersPagination>('/users/admin', { params })
  }

  create(userForm: ICreateUserForm) {
    return http.post<IUsersPagination>('/users/admin/create', userForm)
  }

  get(id: number) {
    return http.get<IUser>(`/users/admin/${id}`)
  }

  update(id: number, form: IUserUpdateForm) {
    return http.patch<IUser>(`users/admin/update/${id}`, form)
  }

  updatePassword(id: number, form: IUserUpdatePasswordForm) {
    return http.patch(`users/admin/update/${id}/password`, form)
  }

  updatePermissions(id: number, form: IUserUpdatePermissionsForm) {
    return http.patch<IUser>(`users/admin/update/${id}/permissions`, form)
  }

  delete(id: number) {
    return http.delete(`users/admin/delete/${id}`)
  }

  updateProfile(form: IUserUpdateForm) {
    return http.patch<IUser>('users/profile', form)
  }

  updateProfilePassword(form: IUserUpdatePasswordForm) {
    return http.patch('users/profile/password', form)
  }

  setPassword(form: ISetPasswordForm) {
    return http.patch('users/profile/password/set', form)
  }
}

export const usersService = new UsersService()
