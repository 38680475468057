import { authRouteNames } from '@/views/auth/auth.routes'
import { usersRouteNames } from '@/views/users/users.routes'
import { accessDeniedRouteNames } from '@/views/access-denied/access-denied.routes'
import { profileRouteNames } from '@/views/profile/profile.routes'
import { permissionsRouteNames } from '@/views/permissions/permissions.routes'
import { productsRouteNames } from '@/views/products/products.routes'
import { ordersRouteNames } from '@/views/orders/orders.routes'
import { orderStatusesRouteNames } from '@/views/order-statuses/order-statuses.routes'
import { clientsRouteNames } from '@/views/clients/clients.routes'
import { workersRouteNames } from '@/views/workers/workers.routes'
import { arViewRouteNames } from '@/views/ar-view/ar-view.routes'

export const routeNames = {
  ...authRouteNames,
  ...usersRouteNames,
  ...accessDeniedRouteNames,
  ...profileRouteNames,
  ...permissionsRouteNames,
  ...productsRouteNames,
  ...ordersRouteNames,
  ...orderStatusesRouteNames,
  ...clientsRouteNames,
  ...workersRouteNames,
  ...arViewRouteNames,
}
