
import { defineComponent, ref, computed } from 'vue'
import { useRouter, useRoute, RouteRecordName } from 'vue-router'
import { ElMenu, ElMenuItem, ElIcon, ElButton } from 'element-plus'

import {
  UserFilled,
  Back,
  Key,
  Goods,
  Notebook,
  ShoppingTrolley,
  CircleCheck,
  Camera,
  Avatar,
  Cpu,
} from '@element-plus/icons-vue'

import { usePermissions } from '@/core/compositions'

export default defineComponent({
  name: 'AppSidebar',

  components: {
    UserFilled,
    ElMenu,
    ElMenuItem,
    ElIcon,
    ElButton,
    Back,
    Key,
    Goods,
    Notebook,
    ShoppingTrolley,
    CircleCheck,
    Camera,
    Avatar,
    Cpu,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()

    const { menuItems } = usePermissions()

    const isCollapse = ref(false)
    const isCollapseFinished = ref(false)

    const activeMenuItemIndex = computed(() => {
      return menuItems.value.findIndex((item) =>
        route.matched.some((r) => r.name === item.routeRootName),
      )
    })

    const toggleCollapse = (): void => {
      isCollapse.value = !isCollapse.value

      if (isCollapse.value) {
        isCollapseFinished.value = isCollapse.value
      } else {
        setTimeout(() => {
          isCollapseFinished.value = isCollapse.value
        }, 600) // 600 - animation time
      }
    }

    const onMenuItemClick = (routeName: RouteRecordName) => {
      router.push({ name: routeName })
    }

    return {
      isCollapse,
      isCollapseFinished,
      menuItems,
      activeMenuItemIndex,
      onMenuItemClick,
      toggleCollapse,
    }
  },
})
