
import { defineComponent } from 'vue'

import AppSidebar from '@/components/stateless/layout/AppSidebar.vue'
import AppHeader from '@/components/stateless/layout/AppHeader.vue'

export default defineComponent({
  name: 'DashboardLayout',

  components: { AppSidebar, AppHeader },
})
