import { ref } from 'vue'

import { IProduct, IProductsPaginationParams, IProductList } from '@/types'
import { productsService } from '@/services'

export const useProducts = () => {
  const products = ref<IProduct[]>([])
  const productsList = ref<IProductList[]>([])

  const getProductsPagination = async (params: IProductsPaginationParams) => {
    return productsService.getPagination(params).then((result) => {
      products.value = result.products

      return result
    })
  }
  const getProductsList = async () => {
    return productsService.getList().then((result) => {
      productsList.value = result

      return result
    })
  }

  const get = async (id: number) => {
    return productsService.get(id).then((result) => {
      if (!result) {
        throw new Error('Product not found!')
      }

      return result
    })
  }

  return {
    products,
    productsList,

    getProductsPagination,
    getProductsList,
    get,
  }
}
