import { RouteRecordRaw } from 'vue-router'

import BlankLayout from '@/layouts/BlankLayout.vue'

const accessDeniedRouteNames = {
  accessDeniedRoot: 'accessDeniedRoot',
  accessDenied: 'accessDenied',
}

const accessDeniedRoutes: RouteRecordRaw = {
  name: accessDeniedRouteNames.accessDeniedRoot,
  path: '/access-denied',
  redirect: { name: accessDeniedRouteNames.accessDenied },
  component: BlankLayout,
  children: [
    {
      name: accessDeniedRouteNames.accessDenied,
      path: '',
      meta: {
        title: 'Access denied!',
        breadcrumbs: [
          {
            label: 'Access denied!',
          },
        ],
      },
      component: () => import('@/views/access-denied/AccessDenied.vue'),
    },
  ],
}

export { accessDeniedRouteNames, accessDeniedRoutes }
