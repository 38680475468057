import { RouteRecordRaw } from 'vue-router'

import BlankLayout from '@/layouts/BlankLayout.vue'

const orderStatusesRouteNames = {
  orderStatusesRoot: 'orderStatusesRoot',
  orderStatuses: 'orderStatuses',
}

const orderStatusesRoutes: RouteRecordRaw = {
  name: orderStatusesRouteNames.orderStatusesRoot,
  path: '/order-statuses',
  redirect: { name: orderStatusesRouteNames.orderStatuses },
  component: BlankLayout,
  children: [
    {
      name: orderStatusesRouteNames.orderStatuses,
      path: '',
      meta: {
        title: 'Order statuses',
        requiresAuth: true,
        breadcrumbs: [
          {
            label: 'Order statuses',
          },
        ],
      },
      component: () => import('@/views/order-statuses/OrderStatuses.vue'),
    },
    //   {
    //     name: orderStatusesRouteNames.createProduct,
    //     path: '/create',
    //     meta: {
    //       title: 'Create product',
    //       requiresAuth: true,
    //       permissions: [EPermissions.CreateProduct],
    //       breadcrumbs: [
    //         {
    //           label: 'Products',
    //           routeName: orderStatusesRouteNames.products,
    //         },
    //         {
    //           label: 'Create product',
    //         },
    //       ],
    //     },
    //     component: () => import('@/views/products/CreateProduct.vue'),
    //   },
    //   {
    //     name: orderStatusesRouteNames.product,
    //     path: ':id',
    //     meta: {
    //       title: 'Product',
    //       requiresAuth: true,
    //       permissions: [EPermissions.ViewProduct],
    //       breadcrumbs: [
    //         {
    //           label: 'Products',
    //           routeName: orderStatusesRouteNames.products,
    //         },
    //         {
    //           label: 'Product',
    //         },
    //       ],
    //     },
    //     component: () => import('@/views/products/Product.vue'),
    //   },
  ],
}

export { orderStatusesRouteNames, orderStatusesRoutes }
