import { AxiosError, AxiosResponse } from 'axios'

import { authStore } from '@/store'

const responseInterceptor = (
  response: AxiosResponse,
): Promise<AxiosResponse> => {
  return response.data
}

const responseErrorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.status === 401) {
    authStore.logout()
  }

  return Promise.reject(error)
}

export { responseInterceptor, responseErrorInterceptor }
