import { http } from '@/services/http'
import { IClientsPagination, IClientsPaginationParams, IClient } from '@/types'

class ClientsService {
  getPagination(params: IClientsPaginationParams) {
    return http.get<IClientsPagination>('/clients', { params })
  }

  // getList() {
  //   return http.get<IProductList[]>('/products/list')
  // }

  // create(form: ICreateProductForm) {
  //   return http.post<IProduct>('/products', form)
  // }

  get(id: number) {
    return http.get<IClient>(`/clients/${id}`)
  }

  // update(id: number, form: IUpdateProductForm) {
  //   return http.patch<IProduct>(`/products/${id}`, form)
  // }

  // delete(id: number) {
  //   return http.delete(`/products/${id}`)
  // }
}

export const clientsService = new ClientsService()
