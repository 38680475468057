import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import DashboardLayout from '@/layouts/DashboardLayout.vue'

import { authRoutes } from '@/views/auth/auth.routes'
import { usersRoutes, usersRouteNames } from '@/views/users/users.routes'
import { accessDeniedRoutes } from '@/views/access-denied/access-denied.routes'
import { profileRoutes } from '@/views/profile/profile.routes'
import { permissionsRoutes } from '@/views/permissions/permissions.routes'
import { productsRoutes } from '@/views/products/products.routes'
import { ordersRoutes } from '@/views/orders/orders.routes'
import { orderStatusesRoutes } from '@/views/order-statuses/order-statuses.routes'
import { clientsRoutes } from '@/views/clients/clients.routes'
import { workersRoutes } from '@/views/workers/workers.routes'

import { arViewRoutes } from '@/views/ar-view/ar-view.routes'

import { routeGuard } from './route-guard'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },

  authRoutes,

  arViewRoutes,

  {
    path: '/',
    redirect: { name: usersRouteNames.users },
    component: DashboardLayout,
    children: [
      usersRoutes,
      accessDeniedRoutes,
      profileRoutes,
      permissionsRoutes,
      productsRoutes,
      ordersRoutes,
      orderStatusesRoutes,
      clientsRoutes,
      workersRoutes,
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(routeGuard)

export { routeNames } from './route-names'

export default router
