import { http } from '@/services/http'
import { IPermissionsPagination, IPermissionsPaginationParams } from '@/types'

class PermissionsService {
  getPagination(params: IPermissionsPaginationParams) {
    return http.get<IPermissionsPagination>('/permissions', { params })
  }
}

export const permissionsService = new PermissionsService()
