import { TIndexedObject } from '@/types'

export const apiResponseErrorMessages: TIndexedObject = {
  // User/Auth
  emailAlreadyUsed: 'Such email already used.',
  userNotFound: 'User not found.',
  invalidPassword: 'Invalid current password.',
  invalidLoginData: 'Invalid login data.',
  passwordAlreadySetted: 'Password already setted.',
  forbidden: 'Access denied!',

  // Products
  thumbnailNotFound: 'Thumbnail not found.',
  productNotFound: 'Product not found.',
  productAlreadyArchived: 'Product already archived.',
  imageOrVideoShouldBeRequired: 'Image or video should be required.',

  // Posters
  imageNotFound: 'Image not found.',
  invalidImageFile: 'Invalid image.',
  videoNotFound: 'Video not found.',
  anchoreNotFound: 'AR anchore not found',
  invalidVideoFile: 'Invalid video.',
  invalidAnchoreFile: 'Invalid AR anchore',
  posterNotFound: 'Poster not found.',
  posterAlreadyArchived: 'Poster already archived.',
  posterAlreadyHasArAnchore: 'Poster already AR anchore.',

  // Orders
  invalidPosterMinMaxCount: 'Invalid poster min/max count.',
  orderWithShopifyIdAlreadyExist: 'Order with such Shopify Id already exist.',
  orderNotFound: 'Order not found.',
  orderAlreadyArchived: 'Order already archived.',
  invalidQRCode: 'Invalid QR-Code.',
  imageIsRequiredInEveryPoster: 'Image is required in every poster.',
  videoIsRequiredInEveryPoster: 'Video is required in every poster.',

  // Order statuses
  orderStatusNotFound: 'Order status not found.',
  orderStatusAlreadyArchived: 'Order status already archived.',
  orderStatusIdsNotValid: 'Order status Ids not valid.',
  orderStatusesOrderNotValid: 'Invalid order status.',

  // Files
  invalidImageFormat: 'Invalid image format.',
  invalidVideoFormat: 'Invalid video format.',
  invalidZptFormat: 'Invalid AR Anchor file format.',
  fileNotFound: 'File not found.',
  fileAlreadyHasPublicUrl: 'Public URL already exist for this file.',

  // Clients
  clientAlreadyExist: 'Client already exist.',
  clientNotFound: 'Client not found.',
  clientAlreadyArchived: 'Client already archived.',
}
