import { RouteRecordRaw } from 'vue-router'

import { EPermissions } from '@/types'
import BlankLayout from '@/layouts/BlankLayout.vue'

const clientsRouteNames = {
  clientsRoot: 'clientsRoot',
  clients: 'clients',
}

const clientsRoutes: RouteRecordRaw = {
  name: clientsRouteNames.clientsRoot,
  path: '/clients',
  redirect: { name: clientsRouteNames.clients },
  component: BlankLayout,
  children: [
    {
      name: clientsRouteNames.clients,
      path: '',
      meta: {
        title: 'Clients',
        requiresAuth: true,
        permissions: [EPermissions.ViewClientsList],
        breadcrumbs: [
          {
            label: 'Clients',
          },
        ],
      },
      component: () => import('@/views/clients/Clients.vue'),
    },
  ],
}

export { clientsRouteNames, clientsRoutes }
