import { RouteRecordRaw } from 'vue-router'

import BlankLayout from '@/layouts/BlankLayout.vue'

const profileRouteNames = {
  profileRoot: 'profileRoot',
  profile: 'profile',
}

const profileRoutes: RouteRecordRaw = {
  name: profileRouteNames.profileRoot,
  path: '/profile',
  redirect: { name: profileRouteNames.profile },
  component: BlankLayout,
  children: [
    {
      name: profileRouteNames.profile,
      path: '',
      meta: {
        title: 'Profile',
        requiresAuth: true,
        permissions: [],
        breadcrumbs: [
          {
            label: 'Profile',
          },
        ],
      },
      component: () => import('@/views/profile/Profile.vue'),
    },
  ],
}

export { profileRouteNames, profileRoutes }
