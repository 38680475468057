import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex justify-between overflow-y-auto" }
const _hoisted_2 = { class: "flex flex-1 flex-col overflow-y-auto" }
const _hoisted_3 = { class: "flex flex-1 overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSidebar = _resolveComponent("AppSidebar")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppHeader),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}