import { http } from '@/services/http'
import {
  IOrder,
  IOrdersPagination,
  IOrdersPaginationParams,
  ICreateOrderForm,
  IOrderStatusForm,
  IUpdateOrderForm,
  IQRCodeOrder,
} from '@/types'

class OrdersService {
  getPagination(params: IOrdersPaginationParams) {
    return http.get<IOrdersPagination>('/orders/admin', { params })
  }

  create(form: ICreateOrderForm) {
    return http.post<IOrder>('/orders/admin', form)
  }

  get(id: number) {
    return http.get<IOrder>(`/orders/admin/${id}`)
  }

  updateStatus(id: number, form: IOrderStatusForm) {
    return http.patch<IOrder>(`/orders/admin/${id}/update-status`, form)
  }

  update(id: number, form: IUpdateOrderForm) {
    return http.patch<IOrder>(`/orders/admin/${id}`, form)
  }

  regeneratePosterArAnchore(posterId: number) {
    return http.post<IOrder>(`/posters/${posterId}/generate-ar-anchore`)
  }

  delete(id: number) {
    return http.delete(`/orders/admin/${id}`)
  }

  getByQRCode(id: number, signature: string) {
    return http.get<IQRCodeOrder>('/orders/qr-code', {
      params: { id, signature },
    })
  }
}

export const ordersService = new OrdersService()
