export interface IFile {
  name: string
}

export interface IUploadFileResponse {
  id: number
  url: string
}

export enum EFileOptimizationStatus {
  New = 'new',
  Busy = 'busy',
  Done = 'done',
  Error = 'error',
}
